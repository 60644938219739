"use client"
import React from 'react';
import BG from '../assets/images/bg.jpg'
import About from '../assets/images/Picture1.jpg'
import Solutions from '../assets/images/aboutus.jpg'
import WHYUS from '../assets/images/Picture4.jpg'
import AnimatedSection from '../components/AnimatedSection';
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import LOGO from '../assets/images/bescore.svg'
import SOLARENERGY from '../assets/images/solarenergylogo.jpg'
import DIGITALENERGY from '../assets/images/comcore.svg'
import HUASAN from '../assets/images/huasan.png'
import Typewriter from '../components/Typewriter/Typewriter';

const Home = () => {

  document.addEventListener("DOMContentLoaded", function () {
    const section = document.querySelector('.bg-no-repeat');

    section.addEventListener('mouseover', function () {
      this.classList.add('translate-x-2', 'translate-y-2');
    });

    section.addEventListener('mouseout', function () {
      this.classList.remove('translate-x-2', 'translate-y-2');
    });
  });

  function scrollToDiv() {
    const targetDiv = document.getElementById('Aboutus');
    targetDiv.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <>
      <section key='header' className="bg-center bg-cover flex justify-center bg-no-repeat bg-blend-multiply h-[90vh] 2xl:[70vh]" style={{ backgroundImage: `url(${BG})` }}>
        <div className="text-center justify-center flex items-center flex-col gap-5">
          <h1 className="text-4xl font-bold text-white lg:text-5xl py-2"><Typewriter text='Thrive Alongside Nature and Explore New Horizons.' delay={30} /></h1>
          <p className="text-2xl text-gray-300 font-semibold lg:text-2xl sm:px-16 lg:px-48">WASPO is specialized in developing energy-efficient sustainable solutions supporting Net Zero journey</p>
          <div className="space-y-4 sm:justify-center sm:space-y-0 sm:space-x-4">
            <button onClick={scrollToDiv} className="bg-blue-950 flex-row gap-1 hover:bg-white focus:ring-4 focus:outline-none inline-flex justify-center items-center py-3 px-5 text-base focus:ring-blue-300 font-medium text-center text-white hover:text-blue-950 rounded-lg">
              Explore More
              <FaRegArrowAltCircleDown />
            </button>
          </div>
        </div>
      </section>
      <AnimatedSection>
        <section key='Aboutus' id='Aboutus' className="text-blue-950 bg-no-repeat bg-center bg-cover flex lg:flex-row flex-col h-[90vh] 2xl:h-[70vh] lg:px-52 px-5 lg:py-32 py-5 gap-10 hover:scale-105 duration-500" style={{ backgroundImage: `url(${About})` }}>
          <div className='lg:flex-1 flex flex-col gap-10 lg:text-start text-center lg:py-0 py-20 '>
            <h1 className='text-blue-950 text-5xl font-bold'>About Us</h1>
            <p className='font-semibold text-lg'><span className="font-bold">WASPO</span>, a leading distributor of solar energy, battery energy storage, and digital energy solutions, is dedicated to providing energy-efficient and sustainable products that support the Net Zero journey across the Middle East, Africa, and Southeast Asia.</p>
            <div className=" space-y-4 sm:justify-center sm:space-y-0 sm:space-x-4">
              <a href="/aboutus" className="hover:bg-white border border-blue-950 bg-blue-950 focus:ring-4 focus:outline-none inline-flex justify-center items-center py-3 px-5 text-base focus:ring-blue-300 font-medium text-center hover:text-blue-950 text-white rounded-lg">
                Read More
                <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
              </a>
            </div>
          </div>
          <div className='lg:flex-1'></div>
        </section>
      </AnimatedSection>
      <AnimatedSection>
        <section key='Solutions' className="bg-no-repeat bg-center bg-cover flex flex-col h-[90vh] 2xl:h-[70vh] bg-blue-100 bg-blend-multiply lg:px-32 px-5 lg:py-32 py-5 lg:gap-10 gap-6 hover:scale-105 duration-500" style={{ backgroundImage: `url(${WHYUS})` }} >
          <div className='flex flex-row'>
            <div className='lg:flex-1'></div>
            <div className='lg:flex-1 flex flex-col gap-5 lg:text-start text-center lg:py-0 py-20'>
              <h1 className='text-5xl font-bold text-blue-950 lg:text-start mb-5'>Solutions</h1>
              <p className='font-semibold text-lg'>We offer environmentally and economically sustainable solutions for power generation across all applications where our Electrical Energy Storage Systems (EESS) reduces dependence on fossil fuel, overall energy cost, and maximizes the use of renewable energy.</p>
              <div className='flex lg:flex-row flex-col lg:gap-10 gap-8 text-lg font-semibold justify-center items-center mb-5'>
                <a href='/solutions/germansolar'><img src={SOLARENERGY} alt='bescore' className='mx-auto max-h-20 w-40' /></a>
                <a href='/solutions/germansolar'><img src={HUASAN} alt='huasan' className='mx-auto max-h-20 w-40 bg-blue-950 px-2 rounded' /></a>
                <a href='/solutions/batterystorage'><img src={LOGO} alt='batterylogo' className='mx-auto max-h-16 w-32' /></a>
                <a href='/solutions/digitalenergy'><img src={DIGITALENERGY} alt='digitallogo' className='mx-auto max-h-20 w-32' /></a>
              </div>
              <div className='flex lg:justify-start justify-center'>
                <a href="/solutions" className="hover:bg-white border border-blue-950 bg-blue-950 focus:ring-4 focus:outline-none inline-flex justify-center items-center py-3 px-5 text-base focus:ring-blue-300 font-medium text-center hover:text-blue-950 text-white rounded-lg">
                  Read More
                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
      </AnimatedSection>
      <AnimatedSection>
        <section key='WhyUs' className="bg-no-repeat bg-center bg-cover flex lg:flex-row flex-col h-[90vh] bg-blue-100 bg-blend-multiply 2xl:h-[70vh] lg:px-52 px-5 lg:py-32 py-5 gap-10 hover:scale-105 duration-500" style={{ backgroundImage: `url(${Solutions})` }}>
          <div className='lg:flex-1 flex flex-col gap-10 lg:text-start text-center lg:py-0 py-20'>
            <h1 className='text-blue-950 text-5xl font-bold'>Why Us</h1>
            <p className='font-semibold text-lg'>Our customized EESS solutions utilise the highest quality LiFePO4 cell technology in proven integrated proprietary Energy Management System (EMS) software to provide our customers with safe and reliable energy storage</p>
            <div className=" space-y-4 sm:justify-center sm:space-y-0 sm:space-x-4">
              <a href="/whywaspo" className="hover:bg-white border border-blue-950 bg-blue-950 focus:ring-4 focus:outline-none inline-flex justify-center items-center py-3 px-5 text-base focus:ring-blue-300 font-medium text-center hover:text-blue-950 text-white rounded-lg">
                Read More
                <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
              </a>
            </div>
          </div>
          <div className='lg:flex-1'></div>
        </section>
      </AnimatedSection>
    </>
  );
};

export default Home;
