import { Breadcrumb, Card } from 'flowbite-react'
import React from 'react'
import KNOWLEDGE from '../assets/images/hjtknowledge.jpg'
import KNOWLEDGE2 from '../assets/images/hjtknowledge2.jpg'
import BIFACIALITY from '../assets/images/bifaciality.jpg'
import LOGO from '../assets/images/solarenergylogo.jpg'
import HUASAN from '../assets/images/huasan.png'
import PDF from '../assets/pdf/solarenergy.pdf'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { LuDownload } from "react-icons/lu";

const SolarEnergy = () => {

    return (
        <div className='min-h-screen w-full mt-24 lg:px-56 px-5'>
            <h1 className='flex text-center justify-center text-3xl font-bold'>Solar Energy</h1>
            <div className='mt-10'>
                <Breadcrumb className='font-black text-2xl'>
                    <Breadcrumb.Item href='/'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/solutions`} >
                        Solutions
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`solutions/solarenergy`} >
                        Solar Energy
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <div className='flex flex-col gap-6'>
                <div className='items-center flex lg:flex-row flex-col justify-between w-full lg:px-16'>
                    <img src={LOGO} alt='logo' className='max-h-20 mt-10 w-72 slide-in-left' />
                    <a href={PDF} download={'HJT Solar.pdf'} className='flex flex-row items-center slide-in-right'>
                        <img src={HUASAN} alt='logo' className='max-h-16 mt-10 w-72 justify-self-end bg-blue-950 px-2 rounded slide-in-right' />
                    </a>
                </div>
                {/* <div className='flex flex-col items-center justify-between w-full'>
                <div className='items-end flex-1 justfiy-end text-end flex'>                
                <img src={HUASANIMG} alt='logo' className='lg:h-52 mt-10 w-full px-2' />
                </div>
                <div className='flex-1'>
                    <img src={HUASAN} alt='logo' className='max-h-16 mt-10 w-72 justify-self-end bg-blue-950 px-2 rounded' />
                </div>
            </div> */}
                <h1 className='font-bold text-xl lg:items-start items-center lg:mb-0 mb-5 mt-6 text-center lg:text-start'><span className='text-orange-500'>HJT.</span> The Future Has Come</h1>
                <p className='font-semibold text-lg'>Compare with TOPCon, <span className='text-orange-500'>HJT</span> has big advantages in efficiency, performance and low carbon footprint. We belives that <span className='text-orange-500'>HJT</span> will become the next mainstream technology in new
                    photovoltaic era, leading the development direction of solar industry.
                </p>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <div className='w-full flex lg:flex-row flex-col lg:mt-0 mt-10 items-center'>
                <div className="flex-1 lg:mr-10 mr-0 flex flex-col gap-12">
                    <h1 className='font-bold text-xl lg:items-start items-center lg:mb-0 mb-5 mt-10 text-center lg:text-start'><span className='text-orange-500'>HJT</span> Knowledge</h1>
                    <p className='font-semibold'><span className='text-orange-500'>HJT</span> cells combine the advantages of crystalline silicon and thin film technologies, with excellent light absorption and passivation effects, and are superior to PERC in efficiency and performance. It is one of the solar cell technologies that increase coversion efficiency and power output to the highest level and also represents the developement direction of the next generation of cell technology platform.</p>
                </div>
                <div className="flex-1 lg:mr-10 max-h-72 w-full mr-0 mt-10">
                    {/* <!-- Row 1 --> */}
                    <img src={KNOWLEDGE} alt='Knowledge' className='max-h-72' />
                </div>
                {/* <!-- Column 1 --> */}
                {/* <div className="flex-1 lg:mt-0 mt-10">
                    <p className='font-semibold text-l'>
                        The natural bifacial symmetrical structure of HJT cells can effectively Improve the power generation capacity on module's backside. The extremely low temperature coefficient enables modules to maintain stable power generation performance in high temperature environments. Excellent low-light performance increases modules power generation period and further imporves power output.
                    </p>
                </div> */}
            </div>
            <div className='w-full flex lg:flex-row flex-col items-center'>
                <div className="flex-1 lg:mr-10 max-h-72 w-full mr-0  self-center flex justify-center lg:order-1 order-2"  >
                    <img src={KNOWLEDGE2} alt='Knowledge2' className='max-h-72 self-center' />
                </div>
                <div className="flex-1 lg:mt-0 mt-10 lg:order-2 order-1">
                    <p className='font-semibold text-l'>
                        The natural bifacial symmetrical structure of <span className='text-orange-500'>HJT</span> cells can effectively Improve the power generation capacity on module's backside. The extremely low temperature coefficient enables modules to maintain stable power generation performance in high temperature environments. Excellent low-light performance increases modules power generation period and further imporves power output.
                    </p>
                </div>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <div className='w-full flex lg:flex-row flex-col'>
                <div className="flex-1">
                    <h1 className='font-bold text-xl lg:items-start items-center  mb-5 mt-10 text-center lg:text-start'>Bifaciality Benefits</h1>
                    {/* <!-- Row 1 --> */}
                    <p className='font-semibold text-l'><span className='text-orange-500'>HJT</span>'s natural bifacial symmetrycal structure makes the modules' bifacility up to 95%. The power generation of <span className='text-orange-500'>HJT</span> cells per watt is about 3% to 6% higher than that of bifacial PERC cells. In practice, the energy yield gain can reach more than 30% benefiting from the power generation on the back side</p>
                </div>
                <div className="flex-1">
                    <img src={BIFACIALITY} alt='bifaciality' className='max-h-58 w-full lg:mt-0 mt-5' />
                </div>
                {/* <!-- Column 1 --> */}
            </div>
            <div className='divider h-[1px] bg-black'></div>
            <h1 className='font-bold text-xl lg:items-start items-center lg:mb-0 mb-5 mt-10 text-center lg:text-start'>Advantages of HJT Technology</h1>
            <div className='w-full lg:mt-5 flex flex-col'>
                <div className="flex-">
                    <p className='font-semibold text-l mb-5'>By using doped microcrystalline silicon or doped microcrystalline oxygen (silicon carbide) and further increasing the doping concentration, reduces the doped layer resistance
                        and lifts the light transmission performance, thereby increasing the current density and cell efficiency.
                        <br />
                        <span className='text-orange-500'>HJT</span> solar cells have the merits of high conversion rate, low temperature coefficient, no PID, no LID, and uniform color, etc.
                        Compared to other solar cell technologies, <span className='text-orange-500'>HJT</span> cell production requires only four low-temperature process steps, resulting in higher productivity and lower losses.
                    </p>
                    <div className='flex lg:flex-row flex-col justify-center items-center mb-5 gap-2 w-full'>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Extreme Temperature Coefficient
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Thinner Wafer Adopted
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center "
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Great Weak Light performance
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center "
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Ultra-low Carbon Footprint
                            </h2>
                        </Card>
                    </div>
                    <div className='flex lg:flex-row flex-col justify-center items-center mb-5 gap-2 w-full'>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center "
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Extremely High Bifaciality
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center "
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Unique SMBB Technology
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-cente"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                Extremely Low Attenuation
                            </h2>
                        </Card>
                        <Card
                            className="max-w-sm h-20 w-40 bg-blue-950 text-center"
                        >
                            <h2 className="flex text-center text-white justify-center text-l font-semibold">
                                NO LID <br /> NO PID
                            </h2>
                        </Card>
                    </div>
                </div>
            </div>
            <div className='divider h-[1px] bg-black mt-8'></div>
            <div className='w-full mt-10 flex lg:flex-row flex-col gap-10 mb-5 lg:mb-5'>
                <div className="flex-1">
                    <h2 className='font-bold text-xl'>Data Download</h2>
                </div>
                <div className="flex-1">
                    <a href={PDF} download={'Solar Energy.pdf'} className='flex flex-row items-center gap-1'>
                        <h2 className='font-bold text-l'>Solar Energy</h2>
                        <LuDownload size={20} />
                    </a>
                </div>
            </div>
            <div className='flex lg:flex-row flex-col justify-between gap-5 py-5 '>
                <a href='/solutions/germansolar/highefficiencymodules' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
                    <p>Learn more about our High Efficiency Modules</p>
                    <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                </a>
                <a href='/solutions/germansolar/customizedmodules' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
                    <p>Learn more about our Customized modules</p>
                    <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                </a>
            </div>
        </div>
    )
}

export default SolarEnergy