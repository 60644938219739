import { Breadcrumb, Card } from 'flowbite-react'
import React from 'react'
import IMG from '../assets/images/aboutus.png'
import PDF from '../assets/pdf/global.pdf'
import AnimatedSection from '../components/AnimatedSection'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { LuDownload } from "react-icons/lu";

const AboutUs = () => {
  return (
    <div className='min-h-screen w-full mt-24 lg:px-56 px-5 '>
      <h1 className='flex text-center justify-center text-3xl font-bold'>About Us</h1>
      <div className='mt-10'>
        {/* <span><a href='/solutions' className='mr-5 text-2xl font-bold'>SOLUTIONS</a><FontAwesomeIcon icon={faArrowRight} /><a href={`/solutions/${props.data?.link}`} className='ml-3 font-bold text-xl'>{props.data?.title}</a></span> */}
        <Breadcrumb className='font-black text-2xl'>
          <Breadcrumb.Item href='/'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/aboutus`} >
            About Us
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <h2 className='mt-10 font-semibold text-lg'>WASPO, an authorized distributor of <span className='font-bold text-blue-950'>BESCORE GMBH</span> (Battery Energy Storage Solutions), <span className='font-bold text-blue-950'>GermanSolar and Huasan</span>(Solar Energy Solutions), and <span className='font-bold text-blue-950'>Comcore</span> (Digital Energy Solutions), is dedicated to providing energy-efficient and sustainable products that support the Net Zero journey across the Middle East, Africa, and Southeast Asia.</h2>
      <AnimatedSection>
        <div className='pt-10'>
          <div className="border border-gray-300 rounded-lg overflow-hidden shadow-md mb-5">
            <img
              src={IMG}
              alt="img"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </AnimatedSection>
      <div className='w-full lg:mt-10 flex lg:flex-row flex-col'>
        <div className="flex-1">
          <h1 className='font-bold text-xl lg:items-start items-center lg:mb-0 mb-5'>ABOUT WASPO</h1>
        </div>
        {/* <!-- Column 1 --> */}
        <div className="flex-1">
          {/* <!-- Row 1 --> */}
          <p className='font-semibold text-l'>
            <ul className="list-disc ml-5">
              <li>We offer environmentally and economically sustainable solutions for power
                generation across all applications where our Electrical Energy Storage
                Systems (EESS) reduces dependence on fossil fuel, overall energy cost, and maximizes the use of renewable energy.</li>
              <li>Our customised Solar/EESS solutions utilise the highest quality LiFePO4 cell
                technology in proven integrated proprietary energy management system (EMS)
                software to provide our customers with safe and reliable energy storage.</li>
              {/* Add more list items as needed */}
            </ul>
            <br />
            <span className='font-bold text-blue-950'>WASPO’s headquarters is based in Dubai Jebel Ali Free Zone – UAE at Jafza Views 19.</span></p>
        </div>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <AnimatedSection>
        <div className='mt-5 lg:text-start text-center'>
          <h1 className='font-bold text-xl lg:items-start items-center lg:mb-20 mb-10'>MISSION & VISION OF WASPO</h1>
          <div className='mt-5 mb-5 flex lg:flex-row flex-col gap-10 items-center'>
            <Card
              className="max-w-sm w-96 h-52 bg-blue-950"
            >
              <h5 className="text-xl text-center tracking-tight text-white">
                <p>
                  Offer renewable energy solutions with clean, high
                  efficiency and affordable.
                </p>
              </h5>
            </Card>
            <Card
              className="max-w-sm w-96 h-52 bg-blue-950"
            >
              <h5 className="text-xl text-center tracking-tight text-white">
                <p>
                  Provide reliable, safe, easy installation and low-maintenance equipment.
                </p>
              </h5>
            </Card>
            <Card
              className="max-w-sm h-52 w-96 bg-blue-950"
            >
              <h5 className="text-xl text-center tracking-tight text-white">
                <p>
                  Service customers and partners across the region.
                </p>
              </h5>
            </Card>
          </div>
        </div>
      </AnimatedSection>
      {/* <div className='divider h-[1px] bg-black mt-20'></div> */}
      {/* <div className='mt-5'>
            <h1 className='font-bold text-xl lg:items-start items-center lg:mb-20 mb-10'>OUR CORE SOLUTION</h1>
            <div className='flex justify-center mb-10'>
              <Card
                className="max-w-sm h-20 bg-blue-950 text-center"
              >
                <h2 className="flex text-center text-white justify-center text-xl font-bold">
                  DIGITAL ENERGY
                </h2>
              </Card>
            </div>
            <div className='mt-5 mb-5 flex lg:flex-row flex-col gap-10 bg-blue'>
              <StepperTimeline />
            </div>
          </div> */}
      <div className='divider h-[1px] bg-black mt-10'></div>
      <div className='w-full mt-10 flex lg:flex-row flex-col gap-10 mb-5 lg:mb-5'>
        <div className="flex-1">
          <h2 className='font-bold text-xl'>Data Download</h2>
        </div>
        <div className="flex-1 ">
          <a href={PDF} download={'AboutUs.pdf'} className='flex flex-row items-center gap-1'>
            <h2 className='font-bold text-l'>About Us</h2>
            <LuDownload size={20} />
            </a>
        </div>
      </div>
      <div className="flex justify-end py-5">
        <a href='/solutions' alt='learnMoreSolutions' className='bg-white flex items-center flex-row border rounded-lg border-blue-950 text-blue-950 hover:text-white hover:bg-blue-950 px-4 font-semibold py-3'>
          <p>Learn more about our solutions</p>
          <HiOutlineArrowRight className="ml-2 h-5 w-5" />
        </a>
      </div>
    </div>
  )
}

export default AboutUs