import { Breadcrumb, Label, Modal, TextInput } from 'flowbite-react'
import React, { useState } from 'react'
import SOLAR from '../assets/images/huasansolution.jpeg'
import DIGITAL from '../assets/images/meteringEquipment/sem/higheff01.png'
import BATTERY from '../assets/images/ciess1.png'

const Solutions = () => {
  const [openModal, setOpenModal] = useState(undefined)
  const [selected, setSelected] = useState('')

  const data = [
    {
      title: '01',
      body: 'Solar Energy',
      button: 'CONTACT US',
      src: SOLAR,
      alt: 'solarenergy',
      directionOfImage: 'left',
      href: '/solutions/germansolar'
    },
    {
      title: '02',
      body: 'Battery Energy Storage',
      button: 'CONTACT US',
      src: BATTERY,
      alt: 'batterystorage',
      directionOfImage: 'right',
      href: '/solutions/batterystorage'
    },
    {
      title: '03',
      body: 'Digital Energy',
      button: 'CONTACT US',
      src: DIGITAL,
      alt: 'digital energy',
      directionOfImage: 'left',
      href: '/solutions/digitalenergy'
    },
  ]

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:info@waspoenergy.com?subject=Contact Us&body=Hello, I am interested in learning more about ${selected}.`;
    window.location.href = mailtoLink;
  }

  return (
    <div className='w-full mt-24 lg:px-56 px-5 mb-5'>
      <h1 className='flex text-center justify-center text-3xl font-bold'>Solutions</h1>
      <div className='mt-10'>
        <Breadcrumb className='font-black text-2xl'>
          <Breadcrumb.Item href='/'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/solutions`} >
            Solutions
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='divider h-[1px] bg-black mt-8'></div>
      <div className='flex lg:flex-row flex-col justify-between gap-2 w-100 py-10 '>
        {data?.map((dat, index) => (
          // <ImageAndText data={dat}/>
          <div key={index} className="flex flex-col text-center gap-5 items-center justify-center">
            <a href={dat?.href} className='hover:scale-105 cursor-pointer'>
              <img src={dat?.src} alt={dat?.alt} className={`${index === 1 ? 'w-96' :  index === 2 ? 'w-56' : 'w-72'} h-52`} loading='lazy' />
            </a>
            <div className='flex flex-col bottom-0 relative justify-center items-center gap-4'>
              <h1 className="text-blue-950 text-center font-bold text-2xl">{dat?.title}</h1>
              <h2 className="text-blue-950 text-center font-bold text-2xl">{dat?.body}</h2>
              <button onClick={() => { setOpenModal('dismissible'); setSelected(dat?.body) }} className="font-semibold rounded-full lg:w-52 w-72 px-4 py-2 border border-blue-950 bg-inherit text-blue-950 hover:bg-blue-950 hover:text-white">{dat?.button}</button>
            </div>
            <Modal show={openModal === 'dismissible'} onClose={() => setOpenModal(undefined)} className='w-full h-full overflow-x-visible'>
              <Modal.Header>Love to know more about our {selected}?</Modal.Header>
              <Modal.Body className="flex flex-col space-y-6">
                <form className="flex flex-col gap-4" onClick={handleSubmit}>
                  <div className="mb-2 block text-center">
                    <Label
                      htmlFor="email"
                      value="Submit Your Email"
                    />
                  </div>
                  <div className="min-w-96">
                    <TextInput
                      id="email"
                      placeholder="info@waspoenergy.com"
                      required
                      type="email"
                    />
                  </div>
                  <div className="flex justify-center">
                    <button type="submit" className="text-white hover:text-blue-950 bg-blue-950 hover:bg-white border border-blue-950 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-5">Submit</button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Solutions